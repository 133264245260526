import { ONE_STEPS_CONTAINER, STEP_POSITION } from './constants'
import { StepData } from '../api-types'
import * as _ from 'lodash'
import { STEP_ROLE } from '../../../constants/roles'

export const isSortableStep = (stepData: StepData) => {
  return _.eq(stepData.role, STEP_ROLE)
}

export const hasValidConnectedStepsContainer = (stepsContainer: Array<any>) => {
  return stepsContainer.length === ONE_STEPS_CONTAINER
}

export const getStepPosition = (stepIndex, numberOfSteps) => {
  const lastStepPosition = numberOfSteps - 2

  if (stepIndex === 0) {
    return STEP_POSITION.FIRST_STEP
  }
  if (stepIndex === lastStepPosition) {
    return STEP_POSITION.LAST_STEP
  }
  return STEP_POSITION.OTHER
}

