export const ONE_STEPS_CONTAINER = 1

export enum NAVIGATION_BUTTONS_ROLES{
  NEXT_BUTTON_ROLE = 'button_next_Role',
  PREVIOUS_BUTTON_ROLE = 'button_previous_Role'
}

export enum STEP_POSITION {
  FIRST_STEP = 'first_step',
  LAST_STEP = 'last_step',
  OTHER = 'other'
}