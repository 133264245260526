import CoreApi from '../core-api'
import {
  ComponentRef,
  ControllerRef,
  StepData,
  ComponentStructre,
  ComponentConnectionItem,
} from '../api-types'
import * as _ from 'lodash'
import {
  LAST_STEP_ROLE,
  ROLE_FORM,
  STEP_ROLE,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
  ROLE_SUBMIT_BUTTON,
} from '../../../constants/roles'
import { NAVIGATION_BUTTONS_ROLES, STEP_POSITION } from './constants'
import { getStepPosition, hasValidConnectedStepsContainer, isSortableStep } from './utils'
import { COMPONENT_TYPES } from '../preset/fields/component-types'
import translations from '../../../utils/translations'
import {
  getComponentByRole,
  fetchPreset,
  convertPreset,
  connectComponentToConnection,
  limitComponentInContainer,
} from '../services/form-service'
import { undoable } from '../utils'

export default class StepsApi {
  private boundEditorSDK: any
  private coreApi: CoreApi

  constructor(boundEditorSDK, coreApi: CoreApi) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
  }

  public selectStep(stepsContainer: ComponentRef, index: number) {
    return this.boundEditorSDK.components.behaviors.execute({
      componentRef: stepsContainer,
      behaviorName: 'changeState',
      behaviorParams: { stateIndex: index },
    })
  }

  private async _moveButton(button, destStep): Promise<void> {
    if (button) {
      const serializedButton = await this.boundEditorSDK.components.serialize({
        componentRef: button,
      })
      await Promise.all([
        this.boundEditorSDK.components.add({
          componentDefinition: serializedButton,
          pageRef: destStep,
        }),
        this.boundEditorSDK.components.remove({ componentRef: button }),
      ])
    }
  }

  public async swapButtons({ step1, step1Button, step2, step2Button }): Promise<void> {
    await this._moveButton(step1Button, step2)
    await this._moveButton(step2Button, step1)
  }

  public async swapNextAndSubmitButtons({ stepWithNextButton, lastStep }): Promise<void> {
    const submitButton = _.first(
      await this.coreApi.findChildComponentsByRole(lastStep, ROLE_SUBMIT_BUTTON)
    )
    const nextButton = _.first(
      await this.coreApi.findChildComponentsByRole(
        stepWithNextButton,
        NAVIGATION_BUTTONS_ROLES.NEXT_BUTTON_ROLE
      )
    )
    await this.swapButtons({
      step1: stepWithNextButton,
      step1Button: nextButton,
      step2: lastStep,
      step2Button: submitButton,
    })
  }

  private async _swapPreviousButtons(srcStep: ComponentRef, destStep: ComponentRef): Promise<void> {
    const srcStepPreviousButton = _.first(
      await this.coreApi.findChildComponentsByRole(
        srcStep,
        NAVIGATION_BUTTONS_ROLES.PREVIOUS_BUTTON_ROLE
      )
    )
    const destStepPreviousButton = _.first(
      await this.coreApi.findChildComponentsByRole(
        destStep,
        NAVIGATION_BUTTONS_ROLES.PREVIOUS_BUTTON_ROLE
      )
    )
    await this.swapButtons({
      step1: srcStep,
      step1Button: srcStepPreviousButton,
      step2: destStep,
      step2Button: destStepPreviousButton,
    })
  }

  public async updateStepsButtons(
    srcStep: ComponentRef,
    srcStepPosition: STEP_POSITION,
    destStep: ComponentRef,
    destStepPosition: STEP_POSITION
  ): Promise<void> {
    if (_.eq(destStepPosition, STEP_POSITION.LAST_STEP)) {
      await this.swapNextAndSubmitButtons({ stepWithNextButton: srcStep, lastStep: destStep })
    } else if (_.eq(srcStepPosition, STEP_POSITION.LAST_STEP)) {
      await this.swapNextAndSubmitButtons({ stepWithNextButton: destStep, lastStep: srcStep })
    }

    if (
      _.eq(srcStepPosition, STEP_POSITION.FIRST_STEP) ||
      _.eq(destStepPosition, STEP_POSITION.FIRST_STEP)
    ) {
      await this._swapPreviousButtons(srcStep, destStep)
    }
  }

  @undoable()
  public async reorderSteps(
    stepsData: StepData[],
    stepsContainerRef: ComponentRef,
    srcIndex: number,
    destIndex: number
  ): Promise<StepData[]> {
    const srcStep = stepsData[srcIndex].componentRef
    const destStep = stepsData[destIndex].componentRef

    await this.updateStepsButtons(
      srcStep,
      getStepPosition(srcIndex, stepsData.length),
      destStep,
      getStepPosition(destIndex, stepsData.length)
    )
    await this.boundEditorSDK.components.arrangement.moveToIndex({
      componentRef: srcStep,
      index: destIndex,
    })
    await this.updateMultiStepFormTitles(stepsContainerRef)
    await this.selectStep(stepsContainerRef, destIndex)

    return this.getSteps(stepsContainerRef)
  }

  public async getSteps(stepsContainer: ComponentRef): Promise<StepData[]> {
    const children: ComponentRef[] = await this.boundEditorSDK.components.getChildren({
      componentRef: stepsContainer,
    })
    const stepsConnections = await this.boundEditorSDK.components.get({
      componentRefs: children,
      properties: ['connections'],
    })
    return _.compact(
      stepsConnections.map(child => {
        const {
          config: { title },
          role,
        } = _.find(child.connections, { isPrimary: true })
        if (_.eq(role, STEP_ROLE) || _.eq(role, LAST_STEP_ROLE)) {
          return { componentRef: child.componentRef, title, role }
        }
      })
    )
  }

  public async updateStepTitle(step: ComponentRef, title): Promise<void> {
    return this.boundEditorSDK.application.sessionState.update({
      stateMap: { [step.id]: { title: title } },
    })
  }

  public async renameStep(currentStepRef, { label, id }, dropDownTitle): Promise<void> {
    await this.updateStepTitle(currentStepRef, dropDownTitle)
    await this.selectStep(currentStepRef, id)
    return this.coreApi.setComponentConnection(currentStepRef, { title: label })
  }

  public async updateMultiStepFormTitles(stepsContainerRef: ComponentRef) {
    const stepsData: StepData[] = await this.getSteps(stepsContainerRef)
    const numOfSortableSteps = stepsData.length - 1
    return Promise.all(
      stepsData.map((stepData, i) => {
        const title = isSortableStep(stepData)
          ? `${i + 1}/${numOfSortableSteps} - ${stepData.title}`
          : stepData.title
        return this.updateStepTitle(stepData.componentRef, title)
      })
    )
  }

  public async updateMultiStepFormsTitles(): Promise<void> {
    const controllers: ControllerRef[] = await this.boundEditorSDK.controllers.listAllControllers()
    await Promise.all(
      _.map(controllers, async ({ controllerRef }) => {
        const stepsContainers = await this.coreApi.findConnectedComponentsByRole(
          controllerRef,
          ROLE_FORM
        )
        if (hasValidConnectedStepsContainer(stepsContainers)) {
          return this.updateMultiStepFormTitles(_.first(stepsContainers))
        }
      })
    )
  }

  public async getCurrentStepRef(componentRef): Promise<ComponentRef> {
    const componentType = await this.boundEditorSDK.components.getType({ componentRef })
    if (componentType !== COMPONENT_TYPES.STATE_BOX) return null

    const currentState = await this.boundEditorSDK.components.behaviors.getRuntimeState({
      componentRef,
    })
    const children = await this.boundEditorSDK.components.getChildren({ componentRef })
    return children[currentState.currentIndex]
  }

  public async loadInitialPanelData(formComponentRef) {
    return Promise.all([
      this.getSteps(formComponentRef),
      this.coreApi.premium.getPremiumRestrictions(),
    ]).then(([stepsData, { restrictions }]) => ({
      stepsData: stepsData,
      restrictions,
    }))
  }

  private async _getPreset(formConnection: ComponentConnectionItem) {
    const { preset: presetKey } = JSON.parse(formConnection.config)
    const locale = await this.boundEditorSDK.info.getLanguage()
    const rawPreset = await fetchPreset(presetKey, locale, reason =>
      this.coreApi.logFetchPresetsFailed(null, reason)
    )
    if (!rawPreset) {
      return
    }
    return convertPreset(rawPreset, {
      controllerId: formConnection.controllerId,
    })
  }

  private async _getNavigationButtonsFromPreset(
    formConnection: ComponentConnectionItem,
    rolesToSearch: { roleLeftButton: string; roleRightButton: string }
  ) {
    const presetStructure = await this._getPreset(formConnection)
    if (!presetStructure) {
      return { leftButton: null, rightButton: null }
    }

    return {
      leftButton: getComponentByRole(presetStructure, rolesToSearch.roleLeftButton),
      rightButton: getComponentByRole(presetStructure, rolesToSearch.roleRightButton),
    }
  }

  private _getNavigationButtonsFromStep(
    stepDefinition: ComponentStructre,
    rolesToSearch: { roleLeftButton: string; roleRightButton: string }
  ) {
    const leftButton = getComponentByRole(stepDefinition, rolesToSearch.roleLeftButton)
    const rightButton = getComponentByRole(stepDefinition, rolesToSearch.roleRightButton)
    return { leftButton, rightButton }
  }

  private async _getNavigationButtons(
    currentStepDefinition: ComponentStructre,
    formConnection: ComponentConnectionItem,
    isLastStep: boolean,
    stepHeight: number
  ): Promise<{
    leftButton: ComponentStructre
    rightButton: ComponentStructre
  }> {
    const rolesToSearch = {
      roleLeftButton: ROLE_PREVIOUS_BUTTON,
      roleRightButton: isLastStep ? ROLE_SUBMIT_BUTTON : ROLE_NEXT_BUTTON,
    }
    const showLeftButton = button => button && _.merge({}, button, { props: { isHidden: false } })

    const {
      leftButton: leftButtonStep,
      rightButton: rightButtonStep,
    } = this._getNavigationButtonsFromStep(currentStepDefinition, rolesToSearch)
    if (leftButtonStep && rightButtonStep) {
      return {
        leftButton: showLeftButton(leftButtonStep),
        rightButton: rightButtonStep,
      }
    }

    const {
      rightButton: rightButtonFromPreset,
      leftButton: leftButtonFromPreset,
    } = await this._getNavigationButtonsFromPreset(formConnection, rolesToSearch)
    return {
      rightButton: limitComponentInContainer(rightButtonFromPreset, stepHeight),
      leftButton: limitComponentInContainer(showLeftButton(leftButtonFromPreset), stepHeight),
    }
  }

  private async _createNewStepStructure(
    currentStepDefinition: ComponentStructre,
    formConnection: ComponentConnectionItem,
    isLastStep: boolean
  ): Promise<ComponentStructre> {
    const { leftButton, rightButton } = await this._getNavigationButtons(
      currentStepDefinition,
      formConnection,
      isLastStep,
      currentStepDefinition.layout.height
    )
    const stepWithNewName = connectComponentToConnection(currentStepDefinition, {
      role: STEP_ROLE,
      config: { title: translations.t('multiStepForm.newStepName') },
      controllerId: formConnection.controllerId,
    })

    return {
      ...stepWithNewName,
      components: [leftButton, rightButton],
    }
  }

  public async updateStepsButtonLabel(stateBoxRef, role, buttonLabel) {
    const steps: ComponentRef[] = await this.boundEditorSDK.components.getChildren({
      componentRef: stateBoxRef,
    })

    const buttons = _.flatten(
      await Promise.all(
        _.map(steps, stepRef => this.coreApi.findChildComponentsByRole(stepRef, role))
      )
    )

    return Promise.all(
      _.map(buttons, buttonRef =>
        this.boundEditorSDK.components.data.update({
          componentRef: buttonRef,
          data: { label: buttonLabel },
        })
      )
    )
  }

  private async _getNewStepIndexData(
    steps: ComponentRef[],
    currentStepIndex: number
  ): Promise<{ stepIndex: number; stepToDuplicateIndex: number; isLastStep: boolean }> {
    const [{ role: lastStepRole }, { role: currentStepRole }] = await Promise.all([
      this.coreApi.getComponentConnection(steps[steps.length - 1]),
      this.coreApi.getComponentConnection(steps[currentStepIndex]),
    ])
    const stepIndex = currentStepRole == LAST_STEP_ROLE ? currentStepIndex : currentStepIndex + 1
    const newLastStepIndex = steps.length
    const isLastStep =
      lastStepRole === LAST_STEP_ROLE
        ? stepIndex === newLastStepIndex - 1
        : stepIndex === newLastStepIndex
    return {
      stepIndex,
      stepToDuplicateIndex: stepIndex - 1,
      isLastStep,
    }
  }

  private async _getNextButtonStrucutre(
    nextButtonRef: ComponentRef,
    formConnection: ComponentConnectionItem,
    coords: { x: number; y: number } | {}
  ) {
    let baseStructure
    if (nextButtonRef) {
      baseStructure = await this.boundEditorSDK.components.serialize({
        componentRef: nextButtonRef,
      })
    } else {
      const presetStructure = await this._getPreset(formConnection)
      baseStructure = getComponentByRole(presetStructure, ROLE_NEXT_BUTTON)
    }

    return _.merge({}, baseStructure, { layout: coords })
  }

  private async _makeLastStepRegularStep(
    lastStepRef: ComponentRef,
    formConnection: ComponentConnectionItem
  ): Promise<void> {
    const [[submitButtonRef], nextButtonRef] = await Promise.all([
      this.coreApi.findChildComponentsByRole(lastStepRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.findComponentByRole(lastStepRef, ROLE_NEXT_BUTTON),
    ])

    let nextButtonCoords = {}
    if (submitButtonRef) {
      const { x, y } = await this.boundEditorSDK.components.layout.get({
        componentRef: submitButtonRef,
      })
      await this.boundEditorSDK.components.remove({ componentRef: submitButtonRef })
      nextButtonCoords = { x, y }
    }

    const nextButtonStructure = await this._getNextButtonStrucutre(
      nextButtonRef,
      formConnection,
      nextButtonCoords
    )

    return this.boundEditorSDK.components.add({
      componentDefinition: nextButtonStructure,
      pageRef: lastStepRef,
    })
  }

  @undoable()
  public async addNewStep(formComponentRef: ComponentRef): Promise<StepData[]> {
    const [{ currentIndex }, steps, currentMultiStepDefinition] = await Promise.all([
      this.boundEditorSDK.components.behaviors.getRuntimeState({
        componentRef: formComponentRef,
      }),
      this.boundEditorSDK.components.getChildren({ componentRef: formComponentRef }),
      this.boundEditorSDK.components.serialize({
        componentRef: formComponentRef,
      }),
    ])
    const { stepIndex, stepToDuplicateIndex, isLastStep } = await this._getNewStepIndexData(
      steps,
      currentIndex
    )
    const formConnection = _.find(currentMultiStepDefinition.connections.items, {
      isPrimary: true,
    })
    const stepStructure = await this._createNewStepStructure(
      currentMultiStepDefinition.components[stepToDuplicateIndex],
      formConnection,
      isLastStep
    )
    await this.boundEditorSDK.components.add({
      pageRef: formComponentRef,
      componentDefinition: stepStructure,
      optionalIndex: stepIndex,
    })

    await Promise.all([
      this.updateMultiStepFormTitles(formComponentRef),
      this.selectStep(formComponentRef, stepIndex),
      isLastStep
        ? this._makeLastStepRegularStep(steps[stepToDuplicateIndex], formConnection)
        : Promise.resolve(),
    ])

    return this.getSteps(formComponentRef)
  }
}
